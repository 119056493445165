<template>
  <div class="fitment-goods-case">
    <van-nav-bar
      title="添加商品"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
    >
    </van-nav-bar>

    <div>
      <div class="goods-list">
        <div v-for="(item, index) in $store.getters.goodsCaseUsable" :key="index" class="goods-item">
          <van-image width="80px" height="80px" :src="item.images[0]" />
          <div class="item-right">
            <div class="name van-multi-ellipsis--l2">
              {{ item.subject }}
            </div>
            <div class="right-bottom">
              <div></div>
              <div>
                <van-button
                  plain
                  size="mini"
                  color="#FF5200"
                  style="width: 56px"
                  @click="choose(item)"
                  >选择</van-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fitmentGoodsCase",
  data() {
    return {
      index: false,
    };
  },
  created() {
    if (this.$route.query.index) {
      this.index = this.$route.query.index;
    }
    console.log(this.$store.getters.goodsCaseUsable);
  },
  methods: {
    // 选择商品
    choose(item) {
      console.log(item);
      this.$store.commit("chooseLink", {
        type: "goods",
        index: this.index,
        value: {
          id: item.productId,
          name: item.subject,
        },
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-goods-case {
  background-color: #f6f6f6;
  .goods-list {
    background-color: #fff;
    height: calc(100vh - 46px);
    overflow: auto;
    .goods-item {
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      padding: 10px;
      .van-image {
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
      }
      .item-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .right-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
</style>